import React from 'react';

const WhatIDo = () => {
  return (
    <div name='whatido' className='w-full sm:h-screen md:h-screen lg:h-screen top-0 box-content flex flex-wrap max-h-full text-gray-300 bg-[#0a0a5a]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full sm:h-screen md:h-screen lg:h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-[#9A1AF8]'>
            What I Do
          </p>
          <p className='py-6 text-[#9A1AF8]'>// Check out some of <span className='text-[#fff]'>things I do</span>:</p>
        </div>

{/* Container */}
        <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-12'>

            {/* Grid Item */}
          <div
            style={{ }}
            className='border-t-4 border-b-4 border-l-4 border-r-4 border-[#9A1AF8] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-1 group-hover:opacity-100'>
              <span className='text-2xl flex justify-center font-bold text-white tracking-wider'>
                Web Development
              </span>
              <div className='pt-8 p-3 text-center sm:text-1 md:text-1xl'>
                {/* <a href='/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Learn more...
                  </button>
                </a> */}
                <p>HTML5, CSS, SASS, SCSS, JavaScript, PHP, React, MySQL, MongoDB</p>
              </div>
            </div>
          </div>
            {/* Grid Item */}
          <div
            style={{ }}
            className='border-t-4 border-b-4 border-l-4 border-r-4 border-[#9A1AF8] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-1 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white flex justify-center tracking-wider'>
                DevOps
              </span>
              <div className='pt-8 p-3 text-center sm:text-1 md:text-1xl'>
                <p>HashiCorp Terraform</p>
              </div>
            </div>
          </div>
            {/* Grid Item */}
          <div
            style={{ }}
            className='border-t-4 border-b-4 border-l-4 border-r-4 border-[#9A1AF8] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-1 group-hover:opacity-100'>
              <span className='text-2xl flex justify-center font-bold text-white tracking-wider'>
                Amazon Web Services
              </span>
              <div className='pt-8 p-3 text-center sm:text-1 md:text-1xl'>
                <p>VPC, EC2, RDS, S3, ROUTE53, BACKUP, INSPECTOR, GUARDDUTY, ECS</p>
              </div>
            </div>
          </div>
            {/* Grid Item */}
          <div
            style={{ }}
            className='border-t-4 border-b-4 border-l-4 border-r-4 border-[#9A1AF8] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-1 group-hover:opacity-100'>
              <span className='text-2xl font-bold flex justify-center text-white tracking-wider'>
                Web3 (*)
              </span>
              <div className='pt-8 p-3 text-center sm:text-1 md:text-1xl'>
                <p>Ethereum, Solidity</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIDo;
