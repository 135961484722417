import { useEffect } from "react";
import ReactGA from "react-ga";
import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import WhatIDo from "./components/WhatIDo";

// ReactGA.initialize("UA-103175639-1");
ReactGA.initialize("G-CPG9Y6F472");

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <div className="flex flex-col box-content">
      <Navbar />
      <Home />
      <About />
      <WhatIDo />
      <Skills />
      {/* <Work /> */}
      <Contact />
    </div>
  );
}

export default App;
