import React, { useEffect, useState } from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import TextTransition, { presets } from "react-text-transition";

// Data
const Resume = `https://data.piotrjurski.com/resume/Piotr_Jurski_Resume.pdf`;

const TEXTS = [
  "Front-End Developer",
  "DevOps Developer",
  "AWS Architect",
  "Web3 Developer"
];

const Home = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      4000 // every 4 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div name='home' className='home-pj w-full h-screen bg-[#008CD3] flow-root'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <p className='text-white'>Hello, my name is</p>
        <h1 className='text-4xl sm:text-7xl font-bold text-[#fff]'>
          Piotr Jurski
        </h1>
        <h2 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6] pj-jobtitle'>
          I'm a <span className='text-[#9A1AF8]'><TextTransition springConfig={presets.gentle} inline={true} direction={"up"} noOverflow={true}>{TEXTS[index % TEXTS.length]}<span className='dot'>.</span></TextTransition></span>
        </h2>
        <p className='text-[#fff] py-4 max-w-[700px]'>
          I specialize in Web Development, DevOps, & Amazon Web Services.
        </p>
        <div>
          <a
          className='text-white group border-2 px-6 py-3 my-2 w-[150px] flex items-center hover:bg-[#9A1AF8] hover:border-[#fff]'
          href={Resume}
          download="Piotr_Jurski_Resume.pdf"
          target={'_blank'}
          rel="noreferrer"
          >
            Resume 
            <span className='ml-2 group-hover:rotate-180 duration-500'>
              <HiArrowNarrowRight className='ml-3' />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
