import React from 'react'

const Contact = () => {
  return (
    <div name='contact' className='contact-pj w-full bg-[#1240C6] max-h-full flex justify-center items-center sm:h-100 md:h-screen lg:h-screen'>
        <form method='POST' action="https://getform.io/f/cfc266f8-34fb-45fa-b656-09df8e2e040f" className='flex flex-col w-[550px] max-w-[75%] w-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-[#9A1AF8] text-gray-300'>Contact</p>
                <p className='text-[#9A1AF8] py-4'>// <span className='text-[#fff]'>Contact me</span> today for more information: </p>
            </div>
            <input className='bg-[#f9f9f9] p-2 text-[#000]' type="text" placeholder='Name' name='name' />
            <input className='my-4 p-2 bg-[#f9f9f9] text-[#000]' type="email" placeholder='Email' name='email' />
            <textarea className='bg-[#f9f9f9] p-2 text-[#000]' name="message" rows="10" placeholder='Message'></textarea>
            <button className='text-white border-2 hover:bg-[#9A1AF8] hover:border-[#fff] px-4 py-3 my-8 mx-auto flex items-center'>Let's Talk</button>
        </form>
    </div>
  )
}

export default Contact